import Modal from 'react-modal';
export const ModalWindow = ({ isOpen, onClose, children }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className='modal-content'
            overlayClassName='modal-overlay'
            contentLabel="Modal">
            <div onClick={onClose} className='close-button'>✖</div>
            {children}
        </Modal>
    );
};

import React, { useRef, useState, useEffect } from 'react';
import Header from '../components/header/header';
import TitleBlock from '../components/blocks/title_block.js'
import ConsultForm from '../components/blocks/consult_form.js'
import Services from '../components/blocks/services.js'
import Cases from '../components/blocks/cases.js'
import Contacts from '../components/blocks/contacts.js'
import Modal from 'react-modal';
import '../styles/modal.css'



const ModalWindow = ({ isOpen, onClose, children }) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className='modal-content'
            overlayClassName='modal-overlay'
            contentLabel="Modal">
            <div onClick={onClose} className='close-button'>✖</div>
            {children}

        </Modal>
    );
};


function Home() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [children, setChildren] = useState(<div>1</div>)


    const openModal = ({ children }) => {
        setChildren(children)
        setModalIsOpen(true)
    }

    const refs = {
        home: useRef(null),
        consult_form: useRef(null),
        services: useRef(null),
        cases: useRef(null),
        contacts: useRef(null)
    };

    useEffect(() => {
        if (window.location.hash) {
            const targetId = window.location.hash.slice(1); // удаляем #
            if (refs[targetId] && refs[targetId].current) {
                refs[targetId].current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            Object.keys(refs).forEach(key => {
                const element = refs[key].current;
                if (element) {
                    const rect = element.getBoundingClientRect();

                    // Добавляем класс show, если элемент в пределах видимости
                    if (rect.top < window.innerHeight - 100 && rect.bottom > 100) {
                        element.classList.add('show');
                    } else {
                        // Убираем класс show, если элемент вышел из видимости
                        element.classList.remove('show');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='home-page'>
            <ModalWindow isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} >{children}</ModalWindow >
            <Header refs={refs} openModal={openModal} />
            <div id="home" ref={refs.home}><TitleBlock openModal={openModal} /></div>
            <div id="consult_form" ref={refs.consult_form} className="fade-in"><ConsultForm openModal={openModal} /></div>
            <div id="services" ref={refs.services} className="fade-in"><Services openModal={openModal} /></div>
            <div id="cases" ref={refs.cases} className="fade-in"><Cases openModal={openModal} /></div>
            <div id="contacts" ref={refs.contacts} className="fade-in"><Contacts openModal={openModal} /></div>
        </div>
    );
}

export default Home;



import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Tg } from '../../images/svg/tg.svg'
import { ReactComponent as Vk } from '../../images/svg/vk.svg'

function Footer() {

    const navigate = useNavigate();

    return (
        <footer className='footer'>
            <div className='footer-content'>
                <div className='f-logo'>
                    <img onClick={() => (navigate('/'))} className='footer-img' src='/imgs/images/logo_ver1.png' alt='' />
                </div>
                <div className='ff-info'>
                    <p className='ffi-1'> Ип Гордеев А.О. </p>
                    <p className='ffi-2'> Инн 230217131388 </p>
                    <p className='ffi-3'> г. Москва, Бутлерова, 17, БЦ NEO GEO </p>
                    <div className='ffi-link'> Политика конфиденциальности </div>
                </div>
                <div className='f-social'>
                    <p>Наши социальные сети:</p>
                    <div className='soc-icons'>
                        <Tg onClick={() => { window.open('https://t.me/smmbig', '_blank') }} className='crs-soc-ico crs-tg' />
                        <Vk onClick={() => { window.open('https://vk.com/cbegachok', '_blank') }} className='crs-soc-ico crs-vk' />
                    </div>
                </div>
                <div className='f-contacts'>
                    <div onClick={() => { window.open('mailto:info@sharit.media', '_blank') }} className='fc-menuitem fc-mail'>
                        <img className='fc-img fc-img-mail' src='/imgs/images/email.da40a98f.png' alt='' />
                        <label className='fc-label'>info@Gordeev.media</label>
                    </div>
                    <div onClick={() => { window.open('tel:+79671648925', '_blank') }} className='fc-menuitem fc-phone'>
                        <img className='fc-img fc-img-phone' src='/imgs/images/phone.7f02738a2.png' alt='' />
                        <label className=''>+7 (967) 164-89-25</label>
                    </div>
                </div>
                <div>
                    <div className='pe-flex'>
                        {/* <img className='pay-elem' src='/payment-ico/mastercard@2x.png' alt='' /> */}
                        <img className='pay-elem sbp' src='/payment-ico/Group 24.png' alt='' />
                        <img className='pay-elem union' src='/payment-ico/Group 25.png' alt='' />
                        <img className='pay-elem sber' src='/payment-ico/Group 26.png' alt='' />
                        <img className='pay-elem' src='/payment-ico/mir.png' alt='' />
                        {/* <img className='pay-elem' src='/payment-ico/visa.png' alt='' /> */}
                        <img className='pay-elem' src='/payment-ico/tbank.svg' alt='' />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

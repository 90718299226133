import { useEffect, useState, useRef } from 'react';
import Header from '../components/header/header';
import { ModalWindow } from '../components/modal/Modal.js';
import { useParams } from 'react-router-dom';
import Dictionary from '../components/blocks/Guides/dictionary';

function GuideSinglePage() {
    const { title } = useParams();
    const [children, setChildren] = useState(<div>1</div>)
    const refs = {
        home: useRef(null),
        consult_form: useRef(null),
        services: useRef(null),
        cases: useRef(null),
        contacts: useRef(null)
    };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = ({ children }) => {
        setChildren(children)
        setModalIsOpen(true)
    }

    return (
        <div className="home-page faq SinglePage">
            <ModalWindow isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} >{children}</ModalWindow >
            <Header refs={refs} openModal={openModal} />
            {/* <div className='faqSinglePage'> */}
            <Dictionary />
            {/* </div> */}
        </div>
    )
}

export default GuideSinglePage
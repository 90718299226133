import React, { useRef, useState, useEffect } from 'react';
import Header from '../components/header/header';

import Modal from 'react-modal';
import '../styles/modal.css'
import '../styles/faq.css'

import { ModalWindow } from '../components/modal/Modal';
import { useNavigate } from 'react-router-dom';


function Guides() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [children, setChildren] = useState(<div>1</div>)
    const navigate = useNavigate();
    const GUIDES = [
        {
            title: "Словарь музыкальной индустрии",
            description: "Этот словарь призван улучшить и расширить понимание индустрии как новичкам так и артистам с опытом",
            link: "/dictionary"
        },
    ]


    const openModal = ({ children }) => {
        setChildren(children)
        setModalIsOpen(true)
    }

    const refs = {
        home: useRef(null),
        consult_form: useRef(null),
        services: useRef(null),
        cases: useRef(null),
        contacts: useRef(null)
    };

    useEffect(() => {
        const handleScroll = () => {
            Object.keys(refs).forEach(key => {
                const element = refs[key].current;
                if (element) {
                    const rect = element.getBoundingClientRect();

                    // Добавляем класс show, если элемент в пределах видимости
                    if (rect.top < window.innerHeight - 100 && rect.bottom > 100) {
                        element.classList.add('show');
                    } else {
                        // Убираем класс show, если элемент вышел из видимости
                        element.classList.remove('show');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='home-page faq'>
            <ModalWindow isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} >{children}</ModalWindow >
            <Header refs={refs} openModal={openModal} />
            <div className='faq-header'>
                <h1 className='page-title'>FAQ</h1>
                <p className='page-description'>
                    Здесь вы найдете ответы на часто задаваемые вопросы о наших продуктах, услугах и процессах.
                    Мы собрали самую актуальную информацию, чтобы помочь вам быстро решить возникающие вопросы.
                </p>
            </div>
            <div className='guide-list'>
                {GUIDES.map((guide, index) => (
                    <div
                        onClick={() => { navigate("/faq" + guide.link) }}
                        key={index}
                        className='guide-item'>
                        <h2>{guide.title}</h2>
                        <p>{guide.description}</p>
                        <div className='linkA'>Читать далее</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Guides;



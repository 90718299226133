import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Для работы с хешами в URL
import '../../../styles/dictionary.css';
import data from "../../../assets/JSON/dictionary.json";
import { IoLink as IoIosLink } from "react-icons/io5";

const Dictionary = () => {
    const [activeLetter, setActiveLetter] = useState('');
    const articleRefs = useRef([]);
    const alphabet = [...new Set(data.map(item => item.title[0]))];
    const location = useLocation(); // Получаем текущий хеш из URL

    // Скролл к элементу при изменении хеша в URL
    useEffect(() => {
        const hash = location.hash.slice(1); // Получаем хеш из URL без символа #
        if (hash) {
            const index = data.findIndex(item => item.title.toLowerCase().replace(/\s+/g, '-') === hash);
            if (index !== -1 && articleRefs.current[index]) {
                setTimeout(() => {
                    articleRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 100); // Задержка для плавного скролла
            }
        }
    }, [location.hash]); // Запускаем, когда хеш меняется

    // Используем IntersectionObserver для отслеживания видимой статьи
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const letter = entry.target.dataset.letter;
                        setActiveLetter(letter); // Устанавливаем активную букву
                    }
                });
            },
            { threshold: 0.6 } // 60% элемента должно быть видно, чтобы считать его активным
        );

        articleRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            articleRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    // Функция для скролла к нужной статье
    const handleScrollToLetter = (letter) => {
        const index = data.findIndex(item => item.title.startsWith(letter));
        if (index !== -1 && articleRefs.current[index]) {
            const element = articleRefs.current[index];
            const elementRect = element.getBoundingClientRect(); // Позиция элемента относительно окна
            const offset = window.pageYOffset; // Текущая вертикальная позиция скролла
            const elementPosition = elementRect.top + offset; // Позиция элемента относительно документа
            const windowHeight = window.innerHeight; // Высота окна браузера
            const targetPosition = elementPosition - (windowHeight / 2) + (elementRect.height / 2) + 200; // Центрируем элемент

            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        }
    };

    const [clickedLink, setClickedLink] = useState(null);

    const handleCopyLink = (title) => {
        const url = `${window.location.origin}/faq/dictionary#${title.toLowerCase().replace(/\s+/g, '-')}`;
        navigator.clipboard.writeText(url).then(() => {
            setClickedLink(title); // Устанавливаем, что ссылка скопирована
            setTimeout(() => setClickedLink(null), 1000); // Убираем сообщение через 2 секунды
        });
    };
    return (
        <>
            <div className='tit-des' style={{ margin: "0 auto", marginTop: "90px", fontSize: "50px", fontWeight: "800", color: "var(--col)" }}>
                СЛОВАРЬ
            </div>
            <p className='tit-des' style={{ maxWidth: "850px", margin: "0 auto", textAlign: "center", marginTop: "10px", marginBottom: "50px" }}>
                Это словарь терминов, связанных с музыкальной индустрией. Включает объяснения ключевых понятий, таких как авторские права, дистрибуция, роялти, договоры, музыкальные форматы и маркетинг. Полезен для музыкантов, продюсеров и всех, кто связан с музыкальным бизнесом.
            </p>

            <div className="container">
                <div className="left-panel">
                    <ul>
                        {alphabet.map(letter => (
                            <li
                                key={letter}
                                onClick={() => handleScrollToLetter(letter)}
                                className={activeLetter === letter ? 'active' : ''}
                            >
                                {letter}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="right-panel">
                    {data.map((item, index) => (
                        <div
                            key={item.title}
                            ref={(el) => (articleRefs.current[index] = el)}
                            data-letter={item.title[0]} // Для отслеживания активной буквы
                            data-title={item.title} // Для использования в хешах
                            id={item.title.toLowerCase().replace(/\s+/g, '-')} // Устанавливаем уникальный id для каждого элемента
                            className={`article ${index % 2 === 0 ? 'odd' : 'notOdd'}`}
                        >
                            <h3 onClick={() => handleCopyLink(item.title)}>
                                {item.title}
                                {clickedLink === item.title
                                    ? <span className='copy-link copied'>Ссылка скопирована!</span>
                                    : <IoIosLink className='copy-link' />}
                            </h3>
                            <p>{item.description}</p>

                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Dictionary;

import React, { useEffect } from "react";
import "../../styles/payment.css";

const PaymentForm = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);


    const submitPaymentForm = (e) => {
        e.preventDefault();
        const form = document.getElementById("payform-tbank");

        form.elements["receipt"].value = JSON.stringify({
            EmailCompany: "info@goldengrooves.io",
            Taxation: "usn_income",
            Items: [
                {
                    Name: form.elements["description"].value,
                    Price: form.elements["amount"].value * 100,
                    Quantity: 1,
                    Amount: form.elements["amount"].value * 100,
                    PaymentMethod: "full_prepayment",
                    PaymentObject: "commodity",
                    Tax: "none",
                    MeasurementUnit: "pc",
                },
            ]
        });

        console.log(form)
        window.pay(form);
    };

    return (
        <form id="payform-tbank" onSubmit={(e) => { submitPaymentForm(e) }}>
            <input type="hidden" name="frame" defaultValue="false" />
            <input type="hidden" name="receipt" />
            <input type="hidden" name="terminalkey" value="1662976990318" />
            
            <h2>Контактные данные</h2>
            <div className="inpGroup">
                <label htmlFor="name">ФИО</label>
                <input type="text" placeholder="ФИО плательщика" name="name" required />
            </div>

            <div className="inpGroup">
                <label htmlFor="phone">Телефон</label>
                <input type="tel" placeholder="Контактный телефон" name="phone" required />
            </div>

            <div className="inpGroup">
                <label htmlFor="email">Email</label>
                <input type="text" placeholder="Электронная почта" name="email" required />
            </div>

            <span className="separator" />

            <h2>Информация о платеже</h2>
            <div className="inpGroup">
                <label htmlFor="amount">Сумма</label>
                <input type="text" placeholder="Сумма" name="amount" required />
            </div>

            <div className="inpGroup">
                <label htmlFor="description">Описание</label>
                <input type="text" placeholder="Описание" name="description" required />
            </div>

            <input type="submit" value="Оплатить" />
        </form >
    );
};

export default PaymentForm;
